define(['app'], function (app) {
  const MS_IN_A_DAY = 24 * 60 * 60 * 1000;
  const DEFAULT_COUNTDOWN_HRS = 21;
  const removeCloak = el => el.classList.remove('cloak');

  const $interval = (fn, ms) => {
    const id = setInterval(fn, ms);
    return () => clearInterval(id);
  };

  const countDown = () => {
    const component = {};

    const _config = {
      props: {
        countDownTo: { // this attribute should be something that `new Date()` could parse
          selector: 'data-count-down-to',
          defaultValue: () => {
            const date = new Date();
            date.setHours(DEFAULT_COUNTDOWN_HRS);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);

            if (Date.now() > date.getTime()) date.setTime(date.getTime() + MS_IN_A_DAY);
            return date.getTime();
          },
          transform: (n) => new Date(parseInt(n)),
        },
      },
      children: {
        hrs: '.countDown_hrs',
        mins: '.countDown_mins',
        secs: '.countDown_secs',
      },
      computed: {
        get remainingHrs() {
          return component.computed.remainingMins / 60;
        },
        get remainingMins() {
          return component.computed.remainingSecs / 60;
        },
        get remainingSecs() {
          return component.computed.remainingMs / 1000;
        },
        get remainingMs() {
          return component.props.countDownTo.getTime() - Date.now();
        },
        get remainingRoundedHrs() {
          return Math.floor(component.computed.remainingHrs);
        },
        get remainingRoundedMins() {
          return Math.floor(component.computed.remainingMins % 60);
        },
        get remainingRoundedSecs() {
          return Math.floor(component.computed.remainingSecs % 60);
        },
        get isCountdownOver() {
          return component.computed.remainingMs <= 0;
        }
      },
    };

    const _init = (element) => {
      component.element = element;

      component.children = {};
      for (let [childrenName, childrenSelector] of Object.entries(_config.children)) {
        component.children[childrenName] = element.querySelector(childrenSelector);
      }

      component.props = {};
      for (let [propName, {selector, defaultValue, transform = x => x}] of Object.entries(_config.props)) {
        component.props[propName] = transform(element.getAttribute(selector) || defaultValue());
      }

      component.tick();

      component.stopTicking = $interval(component.tick, 1000);

      removeCloak(element);

      return component;
    };

    const _tick = () => {
      if(component.computed.isCountdownOver) {
        component.element.classList.add('countDown_hide');
        component.stopTicking();
        return;
      }

      component.children.hrs.innerHTML = component.computed.remainingRoundedHrs;
      component.children.mins.innerHTML = component.computed.remainingRoundedMins;
      component.children.secs.innerHTML = component.computed.remainingRoundedSecs;
    };

    component.init = _init;
    component.tick = _tick;
    component.computed = _config.computed;

    return component;
  };

  return countDown;
});
